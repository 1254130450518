import { Api } from "@/models/class/api.class";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { UjoTrackingReportDto } from "@/models/interface/ujo-tracking-report";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class UjoTrackingReportService extends HttpClient {
  constructor() {
    super();
  }

  getList(params?: RequestQueryParamsModel): Promise<UjoTrackingReportDto[]> {
    return this.get<UjoTrackingReportDto[]>(Api.UjoTrackingReportList, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  download(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.UjoTrackingReportDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const ujoTrackingReportService = new UjoTrackingReportService();
